import {
  buildConfirmationAlertTextContent,
  ConfirmationAlert,
  ConfirmationAlertType,
} from '@components/alerts/ConfirmationAlert';
import { LoadingRing } from '@components/loaders/LoadingRing';
import { BasicTable } from '@components/tables/BasicTable/BasicTable';
import { ActionEditDeleteCell } from '@components/tables/cells/ActionEditDeleteCell';
import { Authorization } from '@lib/Authorization';
import { useDeleteApiV1TechnicalServicesTicketrequestid, useGetApiV1TechnicalServices } from '@sdk-client/hooks';
import { TicketViewSch } from '@sdk-client/models';
import { createColumnHelper, FilterFn } from '@tanstack/react-table';
import { useCallback, useRef, useState } from 'react';
import { Button } from 'react-daisyui';
import { useNavigate } from 'react-router';

export const ExistingTickets = () => {
  const navigate = useNavigate();
  const [itemSelected, setItemSelected] = useState<TicketViewSch | null>(null);
  const modalConfirmationRef = useRef<HTMLDialogElement>(null);
  const [statusFilter, setStatusFilter] = useState<string | null>(null);

  const statusFilterFn = useCallback<FilterFn<TicketViewSch>>((row, columnId, filterValue) => {
    if (filterValue === null) return true;
    const status = row.getValue(columnId) as string;
    return status === filterValue;
  }, []);

  const columnFilters = statusFilter ? [{ id: 'status', value: statusFilter }] : [];

  const onRemoteSucess = () => {
    refetch?.();
  };
  const { data, isPending, refetch } = useGetApiV1TechnicalServices();
  const { mutateAsync: mutateDeleteAsync, isPending: isDeletePending } = useDeleteApiV1TechnicalServicesTicketrequestid(
    itemSelected?.id ?? '',
    {
      userId: '8888', // TODO: get from auth
    },
    {
      mutation: {
        onSuccess: onRemoteSucess,
      },
    }
  );

  const confirmationCallback = async (resp: boolean) => {
    if (resp) {
      await mutateDeleteAsync();
    } else {
      setItemSelected(null);
    }
  };

  const onClickDelete = async (itemId: string) => {
    console.log('Delete ticket: ', itemId);
    if (data) {
      const item = data.find((item) => item.id === itemId);
      if (item) {
        setItemSelected(item);
        modalConfirmationRef.current?.showModal();
      }
    }
  };
  const buildItemTextDetails = (item: TicketViewSch | null) => {
    if (!item) return '';
    return `Ticket Number # ${item.ticketNumber}`;
  };

  const helper = createColumnHelper<TicketViewSch>();
  const columns = [
    helper.accessor('ticketNumber', {
      header: 'Ticket Number',
      size: 30,
      cell: (item) => item.getValue(),
    }),
    helper.accessor('submitter', {
      header: 'Submitter',
      size: 70,
      cell: (item) => item.getValue(),
    }),
    helper.accessor('customer', {
      header: 'Customer',
      size: 70,
      cell: (item) => item.getValue(),
    }),
    helper.accessor('phone', {
      header: 'Phone',
      size: 70,
      cell: (item) => item.getValue(),
    }),
    helper.accessor('email', {
      header: 'E-Mail',
      size: 70,
      cell: (item) => item.getValue(),
    }),
    helper.accessor('typeOfRequest', {
      header: 'Request Type',
      size: 70,
      cell: (item) => item.getValue(),
    }),
    helper.accessor('status', {
      header: 'Status',
      size: 70,
      cell: (item) => item.getValue(),
    }),
    helper.display({
      id: 'edition',
      header: () => <span>Action</span>,
      cell: ({ row }) => (
        <Authorization allowedRoles={['Admin', 'Scientist/Technician', 'Operations']}>
          <ActionEditDeleteCell
            row={row}
            onClickEdit={(id) => navigate(`/ticketing/request/update/${id}`)}
            onClickDelete={onClickDelete}
          />
        </Authorization>
      ),
    }),
  ];

  if (isPending || isDeletePending) return <LoadingRing />;

  return (
    <>
      <ConfirmationAlert
        textContent={buildConfirmationAlertTextContent(
          'Ticket Request',
          ConfirmationAlertType.Delete,
          buildItemTextDetails(itemSelected)
        )}
        ref={modalConfirmationRef}
        callback={confirmationCallback}
      />
      <div className="flex justify-between items-center">
        <div className="flex gap-2">
          <h2 className="text-lg font-bold mb-2">PE Technical Services</h2>
          <div className="border-l border-gray-400 h-8 mx-3"></div>
          <Button
            color="primary"
            size="sm"
            onClick={() => navigate('/ticketing/request')}
          >
            Create New Request
          </Button>
          <div className="border-l border-gray-400 h-8 mx-3"></div>
          <Button
            color="secondary"
            size="sm"
            onClick={() => setStatusFilter('Pending')}
          >
            Pending Request
          </Button>
          <Button
            color="secondary"
            size="sm"
            onClick={() => setStatusFilter('Open')}
          >
            Open Request
          </Button>
          <Button
            color="secondary"
            size="sm"
            onClick={() => setStatusFilter('Closed')}
          >
            Closed Request
          </Button>
          <Button
            color="secondary"
            size="sm"
            onClick={() => setStatusFilter(null)}
          >
            All
          </Button>
        </div>
      </div>
      <BasicTable
        columns={columns}
        data={data || []}
        columnFilters={columnFilters}
        filterDef={statusFilterFn}
      />
    </>
  );
};
