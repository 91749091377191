import { useFormContext, useWatch } from 'react-hook-form';
import { Input } from 'react-daisyui';
import { CellProps, DropDownAdditiveCellMeta } from '@components/tables/EditableTable';
import { useTableConfig } from '@components/tables/EditableTable/ConfigProvider';
import { useMemo } from 'react';
import { Row } from '@components/tables/types';
import { PhysicalStateType } from '@pages/Admin/DropDownListData/types';

export const ConcentrationCell = <T extends Row>({ row: { index, original }, column }: CellProps<T>) => {
  const meta = column.columnDef.meta as DropDownAdditiveCellMeta;
  const { rowsPropertyName } = useTableConfig();
  const { register } = useFormContext();

  const namePrefix = `${rowsPropertyName}.${index}`;

  const additiveTypeValue = useWatch({ name: `${namePrefix}.additiveType`, defaultValue: '' });
  const additiveValue = useWatch({ name: `${namePrefix}.additive`, defaultValue: '' });

  const placeHolder = useMemo(() => {
    if (additiveTypeValue === 'acid' || additiveTypeValue === 'salt') {
      return '%';
    }

    const additiveOptionSelected = meta.options.find((option) => option.value === additiveValue);
    if (additiveOptionSelected) {
      return additiveOptionSelected.physicalStateTypeId === Number(PhysicalStateType.SOLID) ? 'ppt' : 'gpt';
    }

    return '';
  }, [additiveTypeValue, additiveValue, meta.options]);

  return (
    <div className="relative w-full">
      <Input
        className="input input-sm input-bordered w-full"
        disabled={additiveValue === 'water'}
        key={original.id}
        {...register(`${namePrefix}.${column.id}`)}
      />
      <span className="absolute right-3 top-1/2 -translate-y-1/2 text-sm text-gray-500 pointer-events-none">
        {placeHolder}
      </span>
    </div>
  );
};
