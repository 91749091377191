import { ExistingExperimentsTable } from '@components/tables/ExistingExperimentsTable';
import { AccessorKeyColumnDef, FilterFn, createColumnHelper } from '@tanstack/react-table';
import { ReactElement, useCallback, useState } from 'react';
import { Button, Checkbox, Tabs } from 'react-daisyui';
import { useNavigate } from 'react-router';
import { useGetApiV1ExperimentsCorrosion, usePostApiV1ExperimentsCorrosionCopy } from '@sdk-client/hooks';
import { ExperimentRow } from './types';
import { LoadingRing } from '@components/loaders/LoadingRing';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { ColumnFilterable, GlobalFilter } from '@components/filters/GlobalFilter';
import { rankItem } from '@tanstack/match-sorter-utils';
import { OpenedExperiment } from '@pages/experiments/MultipleView';
import { Authorization } from '@lib/Authorization';

type ExistingExperimentsProps = {
  /*change the filter component to a specific element type once it's created*/
  filter?: ReactElement;
  table: ReactElement<ExistingExperimentsProps>;
  createCopies: () => void;
  openSelectedExperiments: () => void;
};

type SelectedItems = {
  expNumber: string;
  checked: boolean;
};
export const ExistingExperiments = (): React.ReactElement => {
  const navigate = useNavigate();
  const [selectedItems, setSelectedItems] = useState<Record<string, SelectedItems>>({});
  //const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');

  const helper = createColumnHelper<ExperimentRow>();
  const columns = [
    helper.display({
      id: 'actions',
      header: 'Select',
      cell: ({ row }) => {
        return (
          <div className="w-full text-center">
            <Checkbox
              size="xs"
              key={row.index}
              id={row.original.id}
              checked={selectedItems[row.original.id]?.checked ?? false}
              onChange={(e) => {
                setSelectedItems({
                  ...selectedItems,
                  [row.original.id]: {
                    expNumber: String(row.original.experimentNumber),
                    checked: e.target.checked,
                  },
                });
              }}
            />
          </div>
        );
      },
    }),
    helper.accessor('projectID', {
      meta: 'ProjectID',
      header: () => <span>Project ID</span>,
      cell: (item) => item.getValue(),
      filterFn: 'includesString',
    }),
    helper.accessor('date', {
      meta: 'Date',
      header: () => <span>Date</span>,
      cell: (item) => item.getValue(),
      filterFn: 'includesString',
    }),
    helper.accessor('operator', {
      meta: 'Operator',
      header: () => <span>Operator</span>,
      cell: (item) => item.getValue(),
      filterFn: 'includesString',
    }),
    helper.accessor('metallurgy', {
      meta: 'Metallurgy',
      header: () => <span>Metallurgy</span>,
      cell: (item) => item.getValue(),
      filterFn: 'includesString',
    }),
    helper.accessor('temperatureDegF', {
      meta: 'Temp (deg F)',
      header: () => <span>Temp (deg F)</span>,
      cell: (item) => item.getValue(),
      filterFn: 'includesString',
    }),
    helper.accessor('incubationTime', {
      meta: 'Time (h)',
      header: () => <span>Time (h)</span>,
      cell: (item) => item.getValue(),
      filterFn: 'includesString',
    }),
    // helper.accessor('desiredAcidConcentration', {
    //   meta: 'Acid Conc',
    //   header: () => <span>Acid Conc</span>,
    //   cell: (item) => item.getValue(),
    //   filterFn: 'includesString',
    // }),
    helper.accessor('corrosion', {
      meta: 'Corrosion (lb/ft2)',
      header: () => <span>Corrosion (lb/ft2)</span>,
      cell: (item) => item.getValue(),
      filterFn: 'includesString',
    }),
    helper.accessor('pittingIndex', {
      meta: 'Pitting Index',
      header: () => <span>Pitting Index</span>,
      cell: (item) => item.getValue(),
      filterFn: 'includesString',
    }),
    helper.accessor('inhibitor', {
      meta: 'inhibitor',
      header: () => <span>Inhibitor</span>,
      cell: (item) => item.getValue(),
      filterFn: 'includesString',
    }),
    helper.accessor('state', {
      meta: 'State',
      header: () => <span>State</span>,
      cell: (item) => item.getValue(),
      filterFn: 'includesString',
    }),
    helper.display({
      id: 'edition',
      header: () => <span>Action</span>,
      cell: ({ row }) => {
        return (
          <Authorization allowedRoles={['Admin', 'Scientist/Technician']}>
            <div className="w-full text-center">
              <Button
                size="xs"
                onClick={() => navigate(`update/${row.original.id}`)}
                endIcon={<PencilSquareIcon className="h-5 w-5" />}
                title="Edit Experiment"
              ></Button>
            </div>
          </Authorization>
        );
      },
    }),
  ];

  const [columnsFilterables, setColumnsFilterables] = useState<ColumnFilterable[]>(() => {
    return columns
      .filter((col) => col.meta)
      .map((col) => ({
        headerName: col.meta as string,
        accessorKey: (col as AccessorKeyColumnDef<ExperimentRow>).accessorKey,
        filterable: true,
      }));
  });

  const fuzzyFilter = useCallback<FilterFn<ExperimentRow>>(
    (row, columnId, value, addMeta) => {
      const column = columnsFilterables.find((col) => col.accessorKey === columnId);

      // Verify if the column has a filter enabled
      if (!column || !column.filterable) {
        return false; // if there is no filterable, do not filter this row
      }

      // Rank the item
      const itemRank = rankItem(row.getValue(columnId), value);

      // Store the itemRank info
      addMeta({
        itemRank,
      });

      // Return if the item should be filtered in/out
      return itemRank.passed;
    },
    [columnsFilterables]
  );

  const {
    data: experiments,
    error: experimentsError,
    isPending: isExperimentsPending,
    isError: isExperimentsError,
    refetch: refetchExperiments,
  } = useGetApiV1ExperimentsCorrosion();

  const {
    mutate,
    isPending: isCopyPending,
    isError: IsCopyError,
    error: copyError,
  } = usePostApiV1ExperimentsCorrosionCopy({
    mutation: {
      onSuccess: () => {
        setSelectedItems({});
        refetchExperiments();
      },
    },
  });

  if (isExperimentsPending || isCopyPending) return <LoadingRing />;
  if (isExperimentsError) return <div>Error: {experimentsError}</div>;
  if (IsCopyError) return <div>Error: {copyError}</div>;

  const rows: ExperimentRow[] = experiments.map((experiment) => ({
    ...experiment,
    selected: false,
  }));

  const getSelectedExperiments = () => Object.keys(selectedItems).filter((id) => selectedItems[id].checked);

  const createCopies = () => {
    const selectedExperimentIds = getSelectedExperiments();
    if (selectedExperimentIds.length > 0) {
      mutate({
        experimentIds: selectedExperimentIds,
      });
    }
  };

  const openSelected = () => {
    const openedExperiments = getSelectedExperiments().map((key) => {
      return {
        ...selectedItems[key],
        key,
      } as OpenedExperiment;
    });

    if (openedExperiments.length > 0) {
      navigate(`/experiments/multipleview/`, { state: { openedExperiments } });
    }
  };

  return (
    <ExistingExperimentsInner
      createCopies={createCopies}
      openSelectedExperiments={openSelected}
      filter={
        <GlobalFilter
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          columnsFilterables={columnsFilterables}
          setColumnsFilterables={setColumnsFilterables}
        />
      }
      table={
        <ExistingExperimentsTable
          columns={columns}
          data={rows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          fuzzyFilterDef={fuzzyFilter}
        />
      }
    />
  );
};

const ExistingExperimentsInner = ({
  filter: Filter,
  table: Table,
  createCopies,
  openSelectedExperiments,
}: ExistingExperimentsProps): React.ReactElement => {
  const navigate = useNavigate();
  return (
    <Tabs>
      <Tabs.RadioTab
        label="tab1"
        name="tab1"
        active={true}
        className="hidden"
      >
        <div className="flex justify-between items-center">
          <div className="flex gap-2">
            <Authorization allowedRoles={['Admin', 'Scientist/Technician']}>
              <Button
                color="primary"
                size="sm"
                onClick={() => navigate('create')}
              >
                Create New Experiment
              </Button>
              <Button
                color="primary"
                size="sm"
                onClick={createCopies}
              >
                Copy Selected Experiments
              </Button>
            </Authorization>
            <Button
              color="primary"
              size="sm"
              onClick={openSelectedExperiments}
            >
              Open Selected Experiments
            </Button>

            {Filter}
          </div>
        </div>

        <div className="pt-2">{Table}</div>
      </Tabs.RadioTab>
    </Tabs>
  );
};
