import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { AdditionRow } from '../../types';
import { getWaterConc2, getWaterVolume2 } from './hcl-calculation';
import { PhysicalStateType, physicalStateTypeNames } from '@pages/Admin/DropDownListData/types';

export const fahrenheitToCelsius = (fahrenheit: number): number => (fahrenheit - 32) * (5 / 9);
export const setWaterVolMass = (
  payload: AdditionRow[],
  sampleVolumeValue: number,
  rowsPropertyName: string,
  setValue: UseFormSetValue<FieldValues>
) => {
  const waterRowIndex = payload.findIndex((item) => item.additive === 'water');
  if (waterRowIndex < 0) return;

  const totalVolume = payload.reduce((acc, item, i) => {
    if (i === waterRowIndex) return acc;

    const volume =
      item.physicalState === physicalStateTypeNames[PhysicalStateType.SOLID] &&
      !isNaN(Number(item.solidVolMass)) &&
      Number(item.solidVolMass) > 0
        ? Number(item.solidVolMass)
        : Number(item.volMass);

    return acc + volume;
  }, 0);

  const waterVolume = getWaterVolume2(sampleVolumeValue, totalVolume);
  const waterConc = getWaterConc2(sampleVolumeValue, waterVolume);

  setValue(`${rowsPropertyName}.${waterRowIndex}.volMass`, waterVolume.toFixed(2));
  setValue(`${rowsPropertyName}.${waterRowIndex}.concentration`, waterConc.toFixed(0));
};
