import { zStringRequired } from '@components/zod/rules';
import * as z from 'zod';
import { PhysicalStateType } from '../types';

export const optionSchema = z.object({
  id: z.string().optional(),
  value: zStringRequired,
  text: zStringRequired,
});

export const optionRelatedSchema = optionSchema.extend({
  relatedObject: z.string().optional(),
  relatedKeys: z.array(z.string()),
  relatedKeysStr: zStringRequired,
});
export const optionAdditiveSchema = optionSchema
  .extend({
    physicalStateTypeId: zStringRequired,
    density: z.number().optional(),
  })
  .superRefine((val, ctx) => {
    if (val.physicalStateTypeId === PhysicalStateType.SOLID) {
      if (typeof val.density !== 'number' || isNaN(val.density)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Required for Solids',
          path: ['density'],
        });
      }
    } else {
      val.density = 0;
    }
  });

export type OptionRelatedType = z.infer<typeof optionRelatedSchema>;
export type OptionType = z.infer<typeof optionSchema>;
export type OptionAdditiveType = z.infer<typeof optionAdditiveSchema>;
