import { useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { CellProps, DropDownAdditiveCellMeta } from '@components/tables/EditableTable';
import { useTableConfig } from '@components/tables/EditableTable/ConfigProvider';
import { additiveDegBeMap } from './constants';
import { getOtherVolumeMass, getStockAcidVolume } from './hcl-calculation';
import { AdditionRow } from '../../types';
import { setWaterVolMass } from './utils';
import { Row } from '@components/tables/types';
import { PhysicalStateType } from '@pages/Admin/DropDownListData/types';
import { calculateVolMassAcidSaltMixtures } from './acid-brine-helpers';

export const VolMassCell = <T extends Row>({ row, column }: CellProps<T>) => {
  const meta = column.columnDef.meta as DropDownAdditiveCellMeta;
  const { setValue, getValues } = useFormContext();
  const { rowsPropertyName } = useTableConfig();

  const useWatchOptions = (name: string) => useWatch({ name, defaultValue: '' });
  const sampleVolumeValue = useWatchOptions(`sampleVolumeMl`);
  const tempC = Number(useWatchOptions(`acidBlendTemperatureDegC`));
  const additiveTypeValue = useWatchOptions(`${rowsPropertyName}.${row.index}.additiveType`);
  const additiveValue = useWatchOptions(`${rowsPropertyName}.${row.index}.additive`);
  const concentrationValue = useWatchOptions(`${rowsPropertyName}.${row.index}.concentration`);
  const stockAcidValue = useWatchOptions(`${rowsPropertyName}.${row.index}.stockAcid`);
  const volMassValue = useWatchOptions(`${rowsPropertyName}.${row.index}.${column.id}`);

  const { additiveOptionSelected, placeHolder } = useMemo(() => {
    const selectedOption = meta.options.find((option) => option.value === additiveValue);
    const placeHolderValue = selectedOption
      ? selectedOption.physicalStateTypeId === Number(PhysicalStateType.SOLID)
        ? 'g'
        : 'mL'
      : '';

    return { additiveOptionSelected: selectedOption, placeHolder: placeHolderValue };
  }, [additiveValue, meta.options]);

  useEffect(() => {
    const setValueFunc = (value: number) =>
      setValue(`${rowsPropertyName}.${row.index}.${column.id}`, isNaN(value) ? '' : value.toFixed(2));
    let volumeMass = 0;
    let solidMass = 0;
    const isHclAcidDegBe = additiveTypeValue === 'acid' && additiveDegBeMap.hasOwnProperty(additiveValue);
    const isAcidSaltMixtures = (additiveTypeValue === 'acid' || additiveTypeValue === 'salt') && !isHclAcidDegBe;
    const isAnyOtherAdd = additiveValue !== 'water' && additiveTypeValue !== 'acid' && additiveTypeValue !== 'salt';
    if (isHclAcidDegBe) {
      const hciConc = additiveDegBeMap[additiveValue];
      volumeMass = getStockAcidVolume(sampleVolumeValue, hciConc, concentrationValue, tempC);
    } else if (isAnyOtherAdd) {
      volumeMass = getOtherVolumeMass(sampleVolumeValue, concentrationValue);
    } else if (isAcidSaltMixtures) {
      volumeMass = calculateVolMassAcidSaltMixtures(
        getValues(`${rowsPropertyName}`) as AdditionRow[],
        tempC,
        sampleVolumeValue,
        row.index
      );
    }

    const isValidDensity = (density: number | undefined): density is number =>
      typeof density === 'number' && density > 0;

    if (
      !isAcidSaltMixtures &&
      additiveOptionSelected &&
      additiveOptionSelected.physicalStateTypeId === Number(PhysicalStateType.SOLID) &&
      isValidDensity(additiveOptionSelected.density)
    ) {
      solidMass = volumeMass / additiveOptionSelected.density;
    }
    setValueFunc(volumeMass === Infinity ? 0 : volumeMass);
    setValue(`${rowsPropertyName}.${row.index}.solidVolMass`, solidMass.toFixed(2));
    setWaterVolMass(getValues(`${rowsPropertyName}`) as AdditionRow[], sampleVolumeValue, rowsPropertyName, setValue);
  }, [
    additiveOptionSelected,
    additiveTypeValue,
    additiveValue,
    column.id,
    concentrationValue,
    getValues,
    row.index,
    rowsPropertyName,
    sampleVolumeValue,
    setValue,
    tempC,
    stockAcidValue,
  ]);

  return (
    <div className="relative w-full">
      <span className="font-sans pr-8">{volMassValue}</span>
      <span className="absolute right-0 top-1/2 -translate-y-1/2 text-sm text-gray-500">{placeHolder}</span>
    </div>
  );
};
