// fluids.ts

import { AdditionRow } from '../../types';
import { additiveDegBeMap } from './constants';
import { FluidStrengthPercentage } from './types';

// Constants and types
const Nlist = 12;

interface Fluid {
  materialNum: number;
  name: string;
  densFac: number;
  mMass: number;
}
// Define fluids and their properties
const fluids: Fluid[] = [
  { materialNum: 1, name: 'HCl', densFac: 0.00472, mMass: 36.5 },
  { materialNum: 2, name: 'ABF', densFac: 0.007, mMass: 57 },
  { materialNum: 3, name: 'Acetic', densFac: 0.0014, mMass: 60 },
  { materialNum: 4, name: 'Formic', densFac: 0.0022, mMass: 46 },
  { materialNum: 5, name: 'Glycolic', densFac: 0.0014, mMass: 76 },
  { materialNum: 6, name: 'Citric', densFac: 0.0042, mMass: 210 },
  { materialNum: 7, name: 'NH4Cl', densFac: 0.0029, mMass: 53.5 },
  { materialNum: 8, name: 'NaCl', densFac: 0.007, mMass: 58.5 },
  { materialNum: 9, name: 'NaBr', densFac: 0.008, mMass: 103 },
  { materialNum: 10, name: 'KCl', densFac: 0.0064, mMass: 84.5 },
  { materialNum: 11, name: 'CaCl2', densFac: 0.0084, mMass: 111 },
  { materialNum: 12, name: 'AlCl3', densFac: 0.005, mMass: 133.5 },
];

// Helper functions
export const numFluids = (): number => Nlist;

export const nameFluid = (i: number): string => {
  return i > Nlist ? 'error' : fluids[i - 1].name;
};

export const molMass = (i: number): number => fluids[i - 1].mMass;

const materialNumFluid = (name: string): number => {
  return fluids.find((fluid) => fluid.name.toLowerCase() === name.toLowerCase())!.materialNum;
};

// Calculate Volume and Mass functions
// this function apply for fluids: HCI, HF, Acetic, Glycolic, Formic
export const calculateAcidVolume = (
  sampleVolume: number,
  fluidName: string,
  stockPerc: number,
  strengthPerc: number,
  densMix: number,
  tempC: number,
  pHF: number,
  pHCl: number,
  pHAc: number,
  pFormic: number,
  pGlyc: number,
  pCitric: number
): number => {
  const materialNumm = materialNumFluid(fluidName);
  return (
    sampleVolume *
    (volStock(materialNumm, stockPerc, strengthPerc, densMix, tempC) +
      hFcorrect(materialNumm, stockPerc, pHF, densMix, tempC, pHCl, pHAc, pFormic, pGlyc, pCitric))
  );
};
// this function apply for fluids: Citric
export const calculateCitricMass = (
  sampleVolume: number,
  pCitric: number,
  densMix: number,
  pHF: number,
  tempC: number,
  pHCl: number,
  pHAc: number,
  pFormic: number,
  pGlyc: number
): number => {
  const materialNumm = 6; // Citric
  return (
    sampleVolume *
    ((pCitric / 100) * densMix + hFcorrect(materialNumm, 0, pHF, densMix, tempC, pHCl, pHAc, pFormic, pGlyc, pCitric))
  );
};
// this function apply for fluids: NH4Cl, NaCl, NaBr, KCl, CaCl2
export const calculateSaltMass = (sampleVolume: number, strengthPerc: number, densMix: number): number => {
  return sampleVolume * (strengthPerc / 100) * densMix;
};
// this function apply for fluids: AlCl3
export const calculateAlCl3Volume = (
  sampleVolume: number,
  stockPerc: number,
  strengthPerc: number,
  densMix: number,
  tempC: number
): number => {
  const materialNumm = 12; // AlCl3
  return sampleVolume * volStock(materialNumm, stockPerc, strengthPerc, densMix, tempC);
};
// this function apply for fluids: HF
export const calculateAbfMass = (sampleVolume: number, pHF: number, densMix: number): number => {
  return sampleVolume * kgABF(pHF, densMix);
};

// Density calculations
export const calculateDensityMixture = (tempC: number, payload: AdditionRow[]) => {
  const fluidComposition = payload
    .filter(
      (item) =>
        (item.additiveType.toLowerCase() === 'acid' || item.additiveType.toLowerCase() === 'salt') &&
        !additiveDegBeMap.hasOwnProperty(item.additive)
    )
    .map((item) => ({
      fluidName: item.additive, // Assuming 'additive' corresponds to 'fluidName'
      percentage: Number(item.concentration), // Assuming 'percentage' is a property of AdditionRow
    }));

  const densityKgLiter = densityMixture(tempC, fluidComposition);
  const densityLbsGal = convertToLblsGal(densityKgLiter);
  return {
    densityKgLiter: densityKgLiter,
    densityLbsGal: densityLbsGal,
  };
};

export const densityMixture = (tempC: number, plist: FluidStrengthPercentage[]): number => {
  let d = densityH2O(tempC);
  plist.forEach((perc) => {
    if (perc.fluidName.toLowerCase() === 'abf') {
      d *= Math.exp(fluids[1].densFac * perc.percentage * 1.425); // HF => ABF correction
    } else {
      d *= Math.exp(
        fluids.find((fluid) => fluid.name.toLowerCase() === perc.fluidName.toLowerCase())!.densFac * perc.percentage
      );
    }
  });
  return d;
};
export const convertToLblsGal = (densityKgLiter: number): number => {
  return (densityKgLiter * 3.785) / 0.454;
};

export const density = (materialNum: number, tempC: number, strength: number): number | string => {
  const d = densityH2O(tempC) * Math.exp(fluids[materialNum - 1].densFac * strength);

  switch (materialNum) {
    case 2:
      return '??'; // HF, no data available at the moment
    case 3:
      return densityHAc(tempC, strength);
    case 4:
      return densityFormic(tempC, strength);
    case 5:
      return densityGlyc(tempC, strength);
    default:
      return d;
  }
};

export const densityH2O = (tempC: number): number => {
  return 0.99999 + 0.0000000175 * Math.pow(tempC, 3) - 0.00000612 * Math.pow(tempC, 2) + 0.0000254 * tempC;
};

export const densityHClN = (tempC: number, N: number): number => {
  return densityH2O(tempC) * (1 + 0.0173 * N - 0.0001 * Math.pow(N, 2));
};

export const densityHClHF = (tempC: number, strengthHCl: number, strengthHF: number): number => {
  const strengthABF = 1.425 * strengthHF;
  return (density(1, tempC, strengthHCl) as number) * Math.exp(fluids[1].densFac * strengthABF);
};

export const densityHAc = (tempC: number, strength: number): number => {
  let x: number;
  if (strength > 100) {
    x = 1.05 + ((strength - 100) * (1.082 - 1.05)) / 12;
  } else {
    x = 1 + 0.12 * (strength / 100) + 0.06 * Math.pow(strength / 100, 2) - 0.13 * Math.pow(strength / 100, 3);
  }
  return densityH2O(tempC) * x;
};

export const densityFormic = (tempC: number, strength: number): number => {
  return densityH2O(tempC) * (1 + 0.0022 * strength);
};

export const densityGlyc = (tempC: number, strength: number): number => {
  return densityHAc(tempC, strength);
};

// Viscosity calculations

export const viscosity = (materialNum: number, tempC: number, strength: number): number | string => {
  switch (materialNum) {
    case 1:
      return viscosityHCl(tempC, strength);
    case 3:
    case 4:
    case 5:
    case 6:
      return viscosityHAc(tempC, strength);
    case 7:
      return viscosityNH4Cl(tempC, strength);
    case 8:
    case 10:
      return viscosityNaCl(tempC, strength);
    case 9:
      return viscosityNaBr(tempC, strength);
    case 11:
      return viscosityCaCl2(tempC, strength);
    default:
      return '??';
  }
};

export const viscosityH2O = (tempC: number): number => {
  const thelp = tempC - 20;
  if (tempC < 20) {
    return 100 * Math.pow(10, 1301 / (998.333 + 8.1855 * thelp + 0.00585 * thelp * thelp) - 3.30233);
  } else {
    return 1.002 * Math.pow(10, (-1.3272 * thelp - 0.001053 * thelp * thelp) / (tempC + 105));
  }
};

export const viscosityHCl = (tempC: number, strength: number): number => {
  const a = 0.0000866;
  const b = -0.0133;
  const d = 0.000924;
  const e = 129;
  const x = (viscosityH2O(tempC) * (density(1, tempC, strength) as number)) / densityH2O(tempC);
  return x * (1 + a * (tempC + e) * (strength + b * Math.pow(strength, 2) + d * Math.pow(strength, 3)));
};

export const viscosityHAc = (tempC: number, strength: number): number => {
  return viscosityH2O(tempC) * (1 + 0.021 * strength);
};

export const viscosityNH4Cl = (tempC: number, strength: number): number => {
  return viscosityH2O(tempC) * (1 - 0.005 * strength);
};

export const viscosityCaCl2 = (tempC: number, strength: number): number => {
  return viscosityH2O(tempC) * (0.85 + 0.15 * Math.exp(0.1 * strength));
};

export const viscosityNaCl = (tempC: number, strength: number): number => {
  return viscosityH2O(tempC) * (1 + 0.01 * strength + 0.0011 * Math.pow(strength, 2));
};

export const viscosityNaBr = (tempC: number, strength: number): number => {
  return (
    viscosityH2O(tempC) * (1 + 0.006 * strength - 0.0003 * Math.pow(strength, 2) + 0.000016 * Math.pow(strength, 3))
  );
};

// Acid calculations

export const kgABF = (strengthHF: number, density: number): number => {
  const kgHF = (strengthHF / 100) * density;
  return kgHF * 0.5 * (57 / 20);
};

export const volStock = (
  materialNum: number,
  pStock: number,
  pX: number,
  densMixture: number,
  tempC: number
): number => {
  const kgX = (pX / 100) * densMixture;
  return kgX / (pStock / 100) / (density(materialNum, tempC, pStock) as number);
};

export const hFfrom = (pHCl: number, pHAc: number, pFormic: number, pGlyc: number, pCitric: number): number => {
  if (pHCl > 0) return 1;
  if (pCitric > 0) return 6;
  if (pFormic > 0) return 4;
  if (pGlyc > 0) return 5;
  if (pHAc > 0) return 3;
  return -1;
};

export const hFcorrectVol = (acidNum: number, pStock: number, pHF: number, densMix: number, tempC: number): number => {
  const kgHF = (pHF / 100) * densMix;
  const kgAcid = kgHF * 0.5 * (fluids[acidNum - 1].mMass / 20);
  return kgAcid / (pStock / 100) / (density(acidNum, tempC, pStock) as number);
};

export const hFcorrect = (
  acidNum: number,
  pStock: number,
  pHF: number,
  densMix: number,
  tempC: number,
  pHCl: number,
  pHAc: number,
  pFormic: number,
  pGlyc: number,
  pCitric: number
): number => {
  if (acidNum !== hFfrom(pHCl, pHAc, pFormic, pGlyc, pCitric)) return 0;

  if (acidNum === 6) {
    const kgHF = (pHF / 100) * densMix;
    return kgHF * 0.5 * (fluids[acidNum - 1].mMass / 20);
  } else {
    return hFcorrectVol(acidNum, pStock, pHF, densMix, tempC);
  }
};

export const pVT_Vn = (P: number, T: number, a: number, b: number, Vguess: number): number | string => {
  let Vnew = Vguess;
  let i = 0;
  let Vn: number;

  do {
    i++;
    if (i > 30) break;
    Vn = Vnew;
    const F = P * Math.pow(Vn, 3) - (P * b + 0.08206 * T) * Math.pow(Vn, 2) + a * Vn - a * b;
    const diffF = 3 * P * Math.pow(Vn, 2) - 2 * (P * b + 0.08206 * T) * Vn + a;
    Vnew = Vn - F / diffF;
  } while (Math.abs(1 - Vn / Vnew) >= 0.001);

  return i > 30 ? 'no convergence' : Vnew;
};
