import { Dropdown, TextBox, valueAsNumberOptional } from '@components/form';
import { Form } from 'react-daisyui';
import { optionAdditiveSchema } from './schema';
import { useFormContextExtended } from '@components/form/hooks/useFormContextExtended';
import { useEffect } from 'react';
import { CatalogType, PhysicalStateType, physicalStateTypeNames } from '../types';
import { DropDownCatalogViewSch } from '@sdk-client/models';
import { useWatch } from 'react-hook-form';

export type AddNewAdditiveItemProps = {
  typeId: CatalogType;
  initialData: DropDownCatalogViewSch | null;
};

export const AddNewAdditiveItem = ({ typeId, initialData }: AddNewAdditiveItemProps) => {
  const { extendRegister, errors, setValue } = useFormContextExtended(optionAdditiveSchema);

  const physicalStateTypeId = useWatch({
    name: `physicalStateTypeId`,
  });

  useEffect(() => {
    if (initialData) {
      setValue('id', initialData.id);
      setValue('value', initialData.value);
      setValue('text', initialData.text);
      setValue('density', initialData.density);
      setValue('physicalStateTypeId', initialData.physicalStateTypeId?.toString() ?? '');
    }
  }, [initialData, setValue]);

  return (
    <>
      <Form>
        <TextBox
          label="Value"
          error={errors.value?.message}
          {...extendRegister('value')}
        />
        <TextBox
          label="Text"
          error={errors.text?.message}
          {...extendRegister('text')}
        />
        <Dropdown
          label="Physical State"
          {...extendRegister('physicalStateTypeId')}
          error={errors.physicalStateTypeId?.message}
          options={[
            {
              id: PhysicalStateType.LIQUID,
              text: physicalStateTypeNames[PhysicalStateType.LIQUID],
              value: PhysicalStateType.LIQUID,
            },
            {
              id: PhysicalStateType.SOLID,
              text: physicalStateTypeNames[PhysicalStateType.SOLID],
              value: PhysicalStateType.SOLID,
            },
          ]}
        />
        {physicalStateTypeId === PhysicalStateType.SOLID && (
          <TextBox
            type="number"
            label="Density"
            error={errors.density?.message}
            {...extendRegister('density', {
              setValueAs: valueAsNumberOptional,
            })}
          />
        )}
      </Form>
    </>
  );
};
