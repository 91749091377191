import { XMarkIcon } from '@heroicons/react/24/outline';
import { Button, Modal } from 'react-daisyui';
import { DropDownDataProps } from './types';
import { FieldValues, SubmitHandler, useFormContext } from 'react-hook-form';
import { forwardRef } from 'react';

type ModalAddItemProps<T extends FieldValues> = {
  isEdition?: boolean;
  reset: () => void;
  onValid: SubmitHandler<T>;
  formComponent: React.ReactElement<DropDownDataProps>;
};

const ModalAddItemComponent = <T extends FieldValues>(
  { isEdition, reset, onValid, formComponent }: ModalAddItemProps<T>,
  ref: React.Ref<HTMLDialogElement>
) => {
  const { handleSubmit } = useFormContext<T>();
  return (
    <Modal ref={ref}>
      <form method="dialog">
        <Button
          size="sm"
          color="ghost"
          shape="circle"
          className="absolute right-2 top-2"
          onClick={() => reset()}
        >
          <XMarkIcon className="w-5 h-5" />
        </Button>
      </form>
      <Modal.Header className="font-bold">{isEdition ? 'Edit Item' : 'Add Item'}</Modal.Header>
      <Modal.Body>{formComponent}</Modal.Body>
      <Modal.Actions>
        <form method="dialog">
          <Button
            color="secondary"
            size="xs"
            onClick={handleSubmit(onValid, (errors) => console.log('errors', errors))}
          >
            {isEdition ? 'Update Item' : 'Add New Item'}
          </Button>
        </form>
      </Modal.Actions>
    </Modal>
  );
};

export const ModalAddItem = forwardRef(ModalAddItemComponent) as <T extends FieldValues>(
  props: ModalAddItemProps<T> & { ref?: React.Ref<HTMLDialogElement> }
) => JSX.Element;
