import { AdditionRow } from '../../types';
import {
  calculateDensityMixture,
  calculateAcidVolume,
  calculateAbfMass,
  calculateCitricMass,
  calculateSaltMass,
  calculateAlCl3Volume,
} from './acid-brine-calculations';

// Calculation type for fluids
enum VolMasCalculationType {
  AcidVolume,
  AbfMass,
  CitricMass,
  SaltMass,
  AlCl3Volume,
}

const fluidVolMassCalculationMap: Record<string, VolMasCalculationType> = {
  hcl: VolMasCalculationType.AcidVolume,
  abf: VolMasCalculationType.AbfMass,
  acetic: VolMasCalculationType.AcidVolume,
  formic: VolMasCalculationType.AcidVolume,
  glycolic: VolMasCalculationType.AcidVolume,
  citric: VolMasCalculationType.CitricMass,
  nh4cl: VolMasCalculationType.SaltMass,
  nacl: VolMasCalculationType.SaltMass,
  nabr: VolMasCalculationType.SaltMass,
  kcl: VolMasCalculationType.SaltMass,
  cacl2: VolMasCalculationType.SaltMass,
  alcl3: VolMasCalculationType.AlCl3Volume,
};

export const fluidWidthStockPerc = ['hcl', 'acetic', 'formic', 'glycolic', 'alcl3'];

// Helper for get pAcid(parameter) for calculateAcidVolume
export const getCalculateAcidVolumeParameters = (payload: AdditionRow[]) => {
  const additives = [
    { name: 'abf', prop: 'pHF' },
    { name: 'hcl', prop: 'pHCl' },
    { name: 'acetic', prop: 'pHAc' },
    { name: 'formic', prop: 'pFormic' },
    { name: 'glycolic', prop: 'pGlyc' },
    { name: 'citric', prop: 'pCitric' },
  ];

  return additives.reduce((acc, { name, prop }) => {
    const item = payload.find((row) => row.additive.toLowerCase() === name);
    acc[prop] = item ? Number(item.concentration) : 0;
    return acc;
  }, {} as Record<string, number>);
};

export const calculateVolMassAcidSaltMixtures = (
  payload: AdditionRow[],
  tempC: number,
  sampleVolume: number,
  currentRowIndex: number
) => {
  const currentRow = payload[currentRowIndex];
  const additiveKey = currentRow.additive.toLowerCase();
  const calculateType = fluidVolMassCalculationMap[additiveKey];

  if (calculateType === undefined) return 0;

  const stockPerc = Number(currentRow.stockAcid);
  const strengthPerc = Number(currentRow.concentration);
  const density = calculateDensityMixture(tempC, payload);
  const densMix = density.densityKgLiter;
  const pAcids = getCalculateAcidVolumeParameters(payload);

  switch (calculateType) {
    case VolMasCalculationType.AcidVolume:
      return calculateAcidVolume(
        sampleVolume,
        additiveKey,
        stockPerc,
        strengthPerc,
        densMix,
        tempC,
        pAcids.pHF,
        pAcids.pHCl,
        pAcids.pHAc,
        pAcids.pFormic,
        pAcids.pGlyc,
        pAcids.pCitric
      );
    case VolMasCalculationType.AbfMass:
      return calculateAbfMass(sampleVolume, pAcids.pHF, densMix);
    case VolMasCalculationType.CitricMass:
      return calculateCitricMass(
        sampleVolume,
        pAcids.pCitric,
        densMix,
        pAcids.pHF,
        tempC,
        pAcids.pHCl,
        pAcids.pHAc,
        pAcids.pFormic,
        pAcids.pGlyc
      );
    case VolMasCalculationType.SaltMass:
      return calculateSaltMass(sampleVolume, strengthPerc, densMix);
    case VolMasCalculationType.AlCl3Volume:
      return calculateAlCl3Volume(sampleVolume, stockPerc, strengthPerc, densMix, tempC);
    default:
      return 0;
  }
};
