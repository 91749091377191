import { TextBox, valueAsNumberOptional } from '@components/form';
import { Collapse } from 'react-daisyui';
import RecipeTable from './recipe-table/RecipeTable';
import useFormContextExtended from './useFormContextExtended';
import { schema } from '../schema';
import { CollapseCustom } from './collapse-custom';

type AcidBlendRecipeProps = {
  openDefault?: boolean;
};

const AcidBlendRecipe = ({ openDefault = false }: AcidBlendRecipeProps) => {
  const { extendRegister, errors } = useFormContextExtended(schema);
  return (
    <CollapseCustom openDefault={openDefault}>
      <Collapse.Title className="text-xl font-medium">Acid Blend Recipe</Collapse.Title>
      <Collapse.Content>
        <div className="flex flex-row gap-5">
          <span>
            <TextBox
              label="Sample Volume (mL)"
              type="number"
              error={errors.sampleVolumeMl?.message}
              {...extendRegister('sampleVolumeMl', {
                setValueAs: valueAsNumberOptional,
              })}
            />
          </span>
          <span>
            <TextBox
              label="Temperature (deg C)"
              type="number"
              value="20"
              error={errors.acidBlendTemperatureDegC?.message}
              {...extendRegister('acidBlendTemperatureDegC', {
                setValueAs: valueAsNumberOptional,
                value: 20,
                disabled: true,
              })}
            />
          </span>
        </div>
        <RecipeTable />
      </Collapse.Content>
    </CollapseCustom>
  );
};

export default AcidBlendRecipe;
