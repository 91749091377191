import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { CellProps } from '@components/tables/EditableTable';
import { useTableConfig } from '@components/tables/EditableTable/ConfigProvider';
import { useEffect } from 'react';
import { Row } from '@components/tables/types';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/16/solid';

export const OrderOfAddition = <T extends Row>({ row, column }: CellProps<T>) => {
  const { getValues, setValue } = useFormContext();
  const { rowsPropertyName } = useTableConfig();
  const { fields, move } = useFieldArray({
    name: rowsPropertyName,
  });

  const acidBlendAdditions = useWatch({
    name: `${rowsPropertyName}`,
    defaultValue: [],
  });

  const moveRow = (index: number, direction: 'up' | 'down') => {
    const newIndex = direction === 'up' ? index - 1 : index + 1;
    if (newIndex >= 0 && newIndex < acidBlendAdditions.length) {
      move(index, newIndex);
    }
  };

  const index = row.index;
  const isFirstRow = index === 0;
  const isLastRow = index === acidBlendAdditions.length - 1;

  useEffect(() => {
    fields.forEach((field, index) => {
      setValue(`${rowsPropertyName}.${index}.orderOfAddition`, (index + 1).toString());
    });
  }, [fields, setValue, rowsPropertyName]);

  return (
    <div className="flex items-center space-x-2">
      <span>{getValues(`${rowsPropertyName}.${row.index}.${column.id}`)}</span>
      <div className="flex flex-col">
        <div
          onClick={!isFirstRow ? () => moveRow(index, 'up') : undefined}
          className={`h-4 w-4 ${isFirstRow ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}
          aria-disabled={isFirstRow}
        >
          <ChevronUpIcon />
        </div>

        <div
          onClick={!isLastRow ? () => moveRow(index, 'down') : undefined}
          className={`h-4 w-4 ${isLastRow ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}
          aria-disabled={isLastRow}
        >
          <ChevronDownIcon />
        </div>
      </div>
    </div>
  );
};
