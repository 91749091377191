import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  TableOptions,
  PaginationState,
  getPaginationRowModel,
  SortingState,
  getSortedRowModel,
  FilterFn,
  getFilteredRowModel,
  ColumnFilter,
} from '@tanstack/react-table';
import { CustomTableMeta, Row } from '../types';
import { Paginator } from '../Paginator';
import { useState } from 'react';
import { BarsArrowDownIcon, BarsArrowUpIcon } from '@heroicons/react/16/solid';

type BasicTableProps<T extends Record<string, any> & Row> = Pick<TableOptions<T>, 'columns' | 'data'> & {
  rowDefaultValues?: T;
  title?: string;
  columnFilters?: ColumnFilter[];
  filterDef?: FilterFn<T>;
};

export const BasicTable = <T extends Record<string, any> & Row>({
  data,
  columns,
  rowDefaultValues,
  title,
  columnFilters,
  filterDef,
}: BasicTableProps<T>) => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const [sorting, setSorting] = useState<SortingState>([]);

  const table = useReactTable<T>({
    data,
    columns,
    state: { pagination, sorting, columnFilters },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(), //client side pagination
    getSortedRowModel: getSortedRowModel(), //client side sorting
    getFilteredRowModel: getFilteredRowModel(),
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    filterFns: {
      customFilter: filterDef ?? (() => true),
    },
    meta: {
      addRow: () => {},
      removeRow: (rowIndex: number) => {},
    } as CustomTableMeta<T>,
  });

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
          <div className="overflow-hidden p-0">
            {title && <h2 className="text-lg font-bold mb-2">{title}</h2>}
            <table className="min-w-full table table-xs border-2 border-separate table-zebra rounded-none">
              <thead className="border-b bg-gray-100">
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        className="px-1 py-1 text-xs font-medium text-gray-900 cursor-pointer"
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {header.isPlaceholder ? null : (
                          <div className="flex items-center justify-between">
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {{
                              asc: <BarsArrowUpIcon className="h-4 w-4" />,
                              desc: <BarsArrowDownIcon className="h-4 w-4" />,
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => {
                  return (
                    <tr
                      key={row.id}
                      className='border-b" bg-white'
                    >
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td
                            className="whitespace-nowrap px-6 py-1 text-sm font-light text-gray-900"
                            key={cell.id}
                          >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Paginator table={table} />
          </div>
        </div>
      </div>
    </div>
  );
};
