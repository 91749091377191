import { createColumnHelper } from '@tanstack/react-table';
import { DropDownCatalogViewSch } from '@sdk-client/models';
import { CatalogType, physicalStateTypeNames } from './types';
import { ActionEditDeleteCell } from '@components/tables/cells/ActionEditDeleteCell';

type CreateColumnsProps = {
  onClickEdit: (itemId: string) => void;
  onClickDelete: (itemId: string) => void;
};

type CreateRelatedColumnsProps = {
  type: CatalogType;
} & CreateColumnsProps;

export const createColumnsRelated = ({ type, onClickEdit, onClickDelete }: CreateRelatedColumnsProps) => {
  const getRelatedColumnText = (type: CatalogType) => {
    switch (type) {
      case CatalogType.EQUIPMENTS:
        return 'Related to Cell Number Value';
      case CatalogType.ADDITIVE_TYPE:
        return 'Related to Additive Value';
      case CatalogType.ADDITIVE:
        return 'Related to Physical State Value';
      default:
        return 'Related';
    }
  };

  const columnHelper = createColumnHelper<DropDownCatalogViewSch>();
  return [
    columnHelper.accessor('value', {
      header: 'Value',
      size: 70,
      cell: (item) => item.getValue(),
    }),
    columnHelper.accessor('text', {
      header: 'Text',
      size: 70,
      cell: (item) => item.getValue(),
      maxSize: 2,
    }),
    columnHelper.accessor('relatedKeys', {
      header: () => getRelatedColumnText(type),
      maxSize: 2,
      size: 1,
      cell: (item) => item.getValue().join(', '),
    }),
    columnHelper.display({
      id: 'actions',
      header: 'Actions',
      cell: ({ row }) => (
        <ActionEditDeleteCell
          row={row}
          onClickEdit={onClickEdit}
          onClickDelete={onClickDelete}
        />
      ),
    }),
  ];
};

export const createColumnsSimple = ({ onClickEdit, onClickDelete }: CreateColumnsProps) => {
  const columnHelper = createColumnHelper<DropDownCatalogViewSch>();
  return [
    columnHelper.accessor('value', {
      header: 'Value',
      cell: (item) => item.getValue(),
    }),
    columnHelper.accessor('text', {
      header: 'Text',
      cell: (item) => item.getValue(),
    }),
    columnHelper.display({
      id: 'actions',
      header: 'Actions',
      cell: ({ row }) => (
        <ActionEditDeleteCell
          row={row}
          onClickEdit={onClickEdit}
          onClickDelete={onClickDelete}
        />
      ),
    }),
  ];
};

export const createAdditiveColumns = ({ type, onClickEdit, onClickDelete }: CreateRelatedColumnsProps) => {
  const columnHelper = createColumnHelper<DropDownCatalogViewSch>();
  return [
    columnHelper.accessor('value', {
      header: 'Value',
      size: 70,
      cell: (item) => item.getValue(),
    }),
    columnHelper.accessor('text', {
      header: 'Text',
      size: 70,
      cell: (item) => item.getValue(),
      maxSize: 2,
    }),
    columnHelper.accessor('physicalStateTypeId', {
      header: () => 'Physical State',
      maxSize: 2,
      size: 1,
      cell: (item) => physicalStateTypeNames[item.getValue()?.toString() || ''],
    }),
    columnHelper.accessor('density', {
      header: 'Density (for solids)',
      size: 70,
      cell: (item) => item.getValue(),
      maxSize: 2,
    }),
    columnHelper.display({
      id: 'actions',
      header: 'Actions',
      cell: ({ row }) => (
        <ActionEditDeleteCell
          row={row}
          onClickEdit={onClickEdit}
          onClickDelete={onClickDelete}
        />
      ),
    }),
  ];
};
