import { ZodEffects, ZodObject, ZodTypeAny } from 'zod';

// function to verify if a schema is a Zod object
function isZodObject(schema: ZodTypeAny): schema is ZodObject<any> {
  return schema._def.typeName === 'ZodObject';
}

export const isFieldRequired = (schema: ZodObject<any> | ZodEffects<ZodObject<any>>, fieldName: string): boolean => {
  const [part, ...rest] = fieldName.split('.');

  if (schema instanceof ZodObject) {
    const field = schema.shape[part];
    if (isZodObject(field) && rest.length > 0) {
      return isFieldRequired(field, rest.join('.'));
    }
    if (!field) {
      throw new Error(`Field '${fieldName}' does not exist on schema.`);
    }
    if (
      field._def.typeName === 'ZodString' ||
      field._def.typeName === 'ZodNumber' ||
      field._def.typeName === 'ZodDefault'
    )
      return field.safeParse('').success === false;
    return false;
  } else if (schema instanceof ZodEffects) {
    return isFieldRequired(schema.innerType(), fieldName);
  } else {
    throw new Error('Invalid schema type');
  }
};
