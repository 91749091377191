import { IAdditiveDegBeMap } from './types';

/**
 * Acid table: reference table for acid HCI concentration, columns definition: [°Bé, SG (60/60F), % HCI]
 */
const acidTable: [number, number, number][] = [
  [1.0, 1.0069, 1.4],
  [2.0, 1.014, 2.82],
  [3.0, 1.0211, 4.25],
  [4.0, 1.0284, 5.69],
  [5.0, 1.0357, 7.15],
  [5.25, 1.0375, 7.52],
  [5.5, 1.0394, 7.89],
  [5.75, 1.0413, 8.26],
  [6.0, 1.0432, 8.64],
  [6.25, 1.045, 9.02],
  [6.5, 1.0469, 9.4],
  [6.75, 1.0488, 9.78],
  [7.0, 1.0507, 10.17],
  [7.25, 1.0526, 10.55],
  [7.5, 1.0545, 10.94],
  [7.75, 1.0564, 11.32],
  [8.0, 1.0584, 11.71],
  [8.25, 1.0603, 12.09],
  [8.5, 1.0623, 12.48],
  [8.75, 1.0642, 12.87],
  [9.0, 1.0662, 13.26],
  [9.25, 1.0681, 13.65],
  [9.5, 1.0701, 14.04],
  [9.75, 1.0721, 14.43],
  [10.0, 1.0741, 14.83],
  [10.25, 1.0761, 15.22],
  [10.5, 1.0781, 15.62],
  [10.75, 1.0801, 16.01],
  [11.0, 1.0821, 16.41],
  [11.25, 1.0841, 16.81],
  [11.5, 1.0861, 17.21],
  [11.75, 1.0881, 17.61],
  [12.0, 1.0902, 18.01],
  [12.25, 1.0922, 18.41],
  [12.5, 1.0943, 18.82],
  [12.75, 1.0964, 19.22],
  [13.0, 1.0985, 19.63],
  [13.25, 1.1006, 20.04],
  [13.5, 1.1027, 20.45],
  [13.75, 1.1048, 20.86],
  [14.0, 1.1069, 21.27],
  [14.25, 1.109, 21.68],
  [14.5, 1.1111, 22.09],
  [14.75, 1.1132, 22.5],
  [15.0, 1.1154, 22.92],
  [15.25, 1.1176, 23.33],
  [15.5, 1.1197, 23.75],
  [15.75, 1.1219, 24.16],
  [16.0, 1.124, 24.57],
  [16.1, 1.1248, 24.73],
  [16.2, 1.1256, 24.9],
  [16.3, 1.1265, 25.06],
  [16.4, 1.1274, 25.23],
  [16.5, 1.1283, 25.39],
  [16.6, 1.1292, 25.56],
  [16.7, 1.1301, 25.72],
  [16.8, 1.131, 25.89],
  [16.9, 1.1319, 26.05],
  [17.0, 1.1328, 26.22],
  [17.1, 1.1336, 26.39],
  [17.2, 1.1345, 26.56],
  [17.3, 1.1354, 26.73],
  [17.4, 1.1363, 26.9],
  [17.5, 1.1372, 27.07],
  [17.6, 1.1381, 27.24],
  [17.7, 1.139, 27.41],
  [17.8, 1.1399, 27.58],
  [17.9, 1.1408, 27.75],
  [18.0, 1.1417, 27.92],
  [18.1, 1.1426, 28.09],
  [18.2, 1.1435, 28.26],
  [18.3, 1.1444, 28.44],
  [18.4, 1.1453, 28.61],
  [18.5, 1.1462, 28.78],
  [18.6, 1.1471, 28.95],
  [18.7, 1.148, 29.13],
  [18.8, 1.1489, 29.3],
  [18.9, 1.1498, 29.48],
  [19.0, 1.1508, 29.65],
  [19.1, 1.1517, 29.83],
  [19.2, 1.1526, 30],
  [19.3, 1.1535, 30.18],
  [19.4, 1.1544, 30.35],
  [19.5, 1.1554, 30.53],
  [19.6, 1.1563, 30.71],
  [19.7, 1.1572, 30.9],
  [19.8, 1.1581, 31.08],
  [19.9, 1.159, 31.27],
  [20.0, 1.16, 31.45],
  [20.1, 1.1609, 31.64],
  [20.2, 1.1619, 31.82],
  [20.3, 1.1628, 32.01],
  [20.4, 1.1637, 32.19],
  [20.5, 1.1647, 32.38],
  [20.6, 1.1656, 32.56],
  [20.7, 1.1666, 32.75],
  [20.8, 1.1675, 32.93],
  [20.9, 1.1684, 33.12],
  [21.0, 1.1694, 33.31],
  [21.1, 1.1703, 33.5],
  [21.2, 1.1713, 33.69],
  [21.3, 1.1722, 33.88],
  [21.4, 1.1732, 34.07],
  [21.5, 1.1741, 34.26],
  [21.6, 1.1751, 34.45],
  [21.7, 1.176, 34.64],
  [21.8, 1.177, 34.83],
  [21.9, 1.1779, 35.02],
  [22.0, 1.1789, 35.21],
  [22.1, 1.1798, 35.4],
  [22.2, 1.1808, 35.59],
  [22.3, 1.1817, 35.78],
  [22.4, 1.1827, 35.97],
  [22.5, 1.1836, 36.16],
  [22.6, 1.1846, 36.35],
  [22.7, 1.1856, 36.54],
  [22.8, 1.1866, 36.73],
  [22.9, 1.1875, 36.93],
  [23.0, 1.1885, 37.14],
  [23.1, 1.1895, 37.36],
  [23.2, 1.1904, 37.58],
  [23.3, 1.1914, 37.8],
  [23.4, 1.1924, 38.03],
  [23.5, 1.1934, 38.26],
  [23.6, 1.1944, 38.49],
  [23.7, 1.1953, 38.72],
  [23.8, 1.1963, 38.95],
  [23.9, 1.1973, 39.18],
  [24.0, 1.1983, 39.41],
  [24.1, 1.1993, 39.64],
  [24.2, 1.2003, 39.86],
  [24.3, 1.2013, 40.09],
  [24.4, 1.2023, 40.32],
  [24.5, 1.2033, 40.55],
  [24.6, 1.2043, 40.78],
  [24.7, 1.2053, 41.01],
  [24.8, 1.2063, 41.24],
  [24.9, 1.2073, 41.48],
  [25.0, 1.2083, 41.72],
  [25.1, 1.2093, 41.99],
  [25.2, 1.2103, 42.3],
  [25.3, 1.2114, 42.64],
  [25.4, 1.2124, 43.01],
  [25.5, 1.2134, 43.4],
];

/**
 * constant variable for acid table in read-only mode
 */
export const ACID_TABLE_RO = Object.freeze(acidTable);

/**
 * constant variable for water density in lb/gal
 */

/**
 * constant variable for conversion factors
 */
export const CONVERSION_FACTORS = {
  ml_gal: 3785.41,
  g_lb: 453.592,
};

export const additiveDegBeMap: IAdditiveDegBeMap = {
  '20_be_hcl': 20,
  '22_be_hcl': 22,
};
