import { createColumnHelper } from '@tanstack/react-table';
import { AdditionRow } from '../types';
import { TextBoxCell, DropDownCellMeta, DropDownAdditiveCellMeta } from '@components/tables/EditableTable';
import { AdditiveTypeCell } from './components/additive-type-cell';
import { AdditiveCell } from './components/additive-cell';
import { PhysicalStateCell } from './components/physical-state-cell';
import { OrderOfAddition } from './components/order-of-addition';
import { ConcentrationCell } from './components/concentration-cell';
import { VolMassCell } from './components/vol-mass-cell';
import { ActionsCell } from './components/actions-cell';
import { StockAcidCell } from './components/stock-acid-cell';

type createColumnsProps = {
  additiveTypeOp: DropDownCellMeta;
  additiveOp: DropDownAdditiveCellMeta;
};

const createColumns = ({ additiveTypeOp, additiveOp }: createColumnsProps) => {
  const columnHelper = createColumnHelper<AdditionRow>();
  return [
    columnHelper.accessor('physicalState', {
      header: 'Physical State',
      cell: PhysicalStateCell,
      meta: additiveOp,
    }),
    columnHelper.accessor('orderOfAddition', {
      header: 'Order of Addition',
      cell: OrderOfAddition,
    }),
    columnHelper.accessor('additiveType', {
      header: 'Additive Type',
      cell: AdditiveTypeCell,
      meta: additiveTypeOp,
    }),
    columnHelper.accessor('additive', {
      header: 'Additive',
      cell: AdditiveCell,
      meta: { source: additiveOp, parent: additiveTypeOp },
    }),
    columnHelper.accessor('stockAcid', {
      header: 'Stock Acid',
      cell: StockAcidCell,
    }),
    columnHelper.accessor('lotNumber', {
      header: 'Lot Number',
      cell: TextBoxCell,
    }),
    columnHelper.accessor('concentration', {
      header: 'Desired Conc',
      cell: ConcentrationCell,
      meta: additiveOp,
    }),
    columnHelper.accessor('volMass', {
      header: 'Vol/Mass',
      cell: VolMassCell,
      meta: additiveOp,
    }),
    columnHelper.display({
      id: 'actions',
      header: 'Actions',
      cell: ActionsCell,
    }),
  ];
};

export default createColumns;
